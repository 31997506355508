<template lang="pug">
TableV2(
  :isViewAdd="false"
  :headers="headers"
  :items="items"
  :pageCount="0"
  :currentPage="0"
  :querySearch="getCrewingGroups"
  :isLoading="isLoading"
  :actions="actions"
  isHiddenTableSearch
  isHiddenTableCount
  isHiddenPageCount)
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BackOfficeCrewingGroups',
  data () {
    return {
      headers: [
        { value: 'id', text: this.$t('id'), sortable: false },
        { value: 'name', text: this.$t('group'), sortable: false },
        { value: 'event', text: this.$t('actions'), sortable: false, align: 'center' }
      ],
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.go',
          action: item => this.$router.push({ name: 'crewing-companies-backoffice', params: { groupID: item.id }, query: { ...this.$route.query } }),
          color: 'primary',
          name: 'mdi-arrow-right',
          isView: item => item.id
        }
      ]
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId,
      items: state => state.directory.crewingGroups,
      isLoading: state => state.directory.isLoading
    })
  },
  methods: {
    ...mapActions(['getCrewingGroups'])
  }
}
</script>
